import { pageview } from '@marine/lib';
import { Theme } from '@marine/theme';
import '@marine/theme/antd/antd.min.css';
import * as Sentry from '@sentry/nextjs';
import { GraphQLClient } from 'graphql-request';
import { getMessages } from 'lang';
import App, { AppProps } from 'next/app';
import { Inter } from 'next/font/google';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { ConfigProvider, GraphQLClientProvider, GraphQLClientState, useConfig } from 'providers';
import 'keen-slider/keen-slider.min.css';
import { withPasswordProtect } from '@marine/next-password-protect';
import { Hydrate, QueryClientProvider, dehydrate } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useGetPostsCategories, useGetRecentPosts, useGetTags } from 'services/graphql/cms';
import { getQueryClient, queryClient } from 'services/queryClient';
import { allPostCategoriesVariables, allPostTagsVariables } from 'services/queryClient/defaultQueryVariables';
import { cmsGraphQLClient } from '../graphQLClient';
import { browserEnv, getAppConfiguration } from '../utils/browserEnv';
import '../styles/main.scss';
import Script from 'next/script';

const { appCode } = getAppConfiguration();

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});

Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
const inter = Inter({ subsets: ['latin'] });

const Main = ({ Component, pageProps }: AppProps<{ dehydratedState: unknown }>) => {
  const { googleAnalyticId, mdcGraphQlUrl } = useConfig();

  const router = useRouter();

  const appGraphQLClient: GraphQLClientState = {
    graphQLClient: new GraphQLClient(mdcGraphQlUrl || ''),
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url, googleAnalyticId);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, googleAnalyticId]);

  const handleGlobalError = (error) => {
    Sentry.captureException(error);
  };

  const browserQueryClient = getQueryClient({ onError: handleGlobalError });

  const [reactQueryClient] = useState(browserQueryClient);

  return (
    <Theme fonts={inter.style.fontFamily} className={inter.className}>
      <GraphQLClientProvider defaultState={appGraphQLClient}>
        <QueryClientProvider client={reactQueryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <Script
              strategy="afterInteractive"
              src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticId}`}
            />
            <Script
              id="gtag-init"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', '${googleAnalyticId}', {
                            page_path: window.location.pathname,
                          });
                        `,
              }}
            />
            <Component {...pageProps} />
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </GraphQLClientProvider>
    </Theme>
  );
};

const AppWithIntl = (props) => {
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const messages = getMessages(locale);

  return (
    <IntlProvider defaultLocale={defaultLocale} locale={locale || 'en'} messages={messages}>
      <Main {...props} />
    </IntlProvider>
  );
};

const AppWithConfigs = (props) => (
  <ConfigProvider values={getAppConfiguration()}>
    <AppWithIntl {...props} />
  </ConfigProvider>
);

AppWithConfigs.getInitialProps = async (appContext) => {
  await App.getInitialProps(appContext);

  await queryClient.prefetchQuery(
    ['getPostsCategories', allPostCategoriesVariables],
    () => useGetPostsCategories.fetcher(cmsGraphQLClient, allPostCategoriesVariables)(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
  await queryClient.prefetchQuery(
    ['getPostsTags', allPostTagsVariables],
    () => useGetTags.fetcher(cmsGraphQLClient, allPostTagsVariables)(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  await queryClient.prefetchQuery(
    ['getRecentPosts', { appCode, pageSize: 5 }],
    () => useGetRecentPosts.fetcher(cmsGraphQLClient, { appCode, pageSize: 5 })(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return {
    pageProps: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

const ProtectedApp = withPasswordProtect(AppWithConfigs, {
  loginApiUrl: '/api/login',
  checkApiUrl: '/api/passwordCheck',
});

export default browserEnv.NEXT_PUBLIC_PASSWORD_PROTECT ? ProtectedApp : AppWithConfigs;
